import type { UserVariant, ResExposure } from '~/types/amplitude/types'

type ExposureKeys = 'multi-inquiry-from-search' | 'online-booking-summary'

export const useExperimentExposure = () => {
  const { $httpCookies } = useNuxtApp()
  const { authUserIdTracking } = useAuth()

  const userVariant = useState<UserVariant>('user-variant')

  if (!userVariant.value && process.env.NODE_ENV === 'development') {
    console.error('No user variant found')
  }

  const sendExposure = (flagKey: ExposureKeys) => {
    if (flagKey in userVariant.value) {
      return $fetch<ResExposure>('/api/expose', {
        query: {
          flagKey,
          userId: authUserIdTracking.value,
          deviceId: $httpCookies.get('_amp'),
          variant: userVariant.value[flagKey],
        },
      })
    }
  }

  return {
    sendExposure,
  }
}
